import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Articles from '../components/articles';

const IndexPage = ({data, location}) => {
  const siteTitle = `Title` || data.site.siteMetadata?.title ;
  const states = data.allStatesCsv.nodes;
  const articles = data.allMarkdownRemark.nodes;

  const currentTaxYear = new Date().getFullYear() ;

  return (
    <Layout>
      <SEO 
      title={"Income Tax Brackets, Tax Rates, and Tax Forms."} 
      description={"Find tax brackets, rates, and tax information for all 50 states in the US. Download up-to-date " + currentTaxYear +" tax forms"}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h1 className="text-center" style={{ marginTop: `40px`}}>See tax information for your state</h1>
            <p className="text-center">Welcome to your ultimate tax rate info guide for 2021</p>
            <div className="location-grid--wrapper" style={{ listStyle: `none` }}>
              <div className="location-grid">
                {states.map(state => {
                  const { State, Code, slug} = state
                  const link = "locations/" + slug

                  return (
                    <div className="location-grid__item" key={Code}>
                      <Link
                        to={"locations/" + slug}
                      >
                        {State}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 pt-5">
            <Articles articles={articles} />
          </div>
        </div>

      </div>


    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allStatesCsv {
      nodes {
        State
        slug
        Code        
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
