import * as React from "react"
import {Link} from "gatsby";
import PropTypes from 'prop-types';

const Articles = ({articles}) => (
  <div>
    <ol style={{listStyle: `none`}}>
      {articles.map(article => {
        const title = article.frontmatter.title || article.fields.slug

        return (
          <li key={article.fields.slug}>
            <article
              className="article-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2>
                  <Link to={"/article" + article.fields.slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
                <small>{article.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.frontmatter.description || article.excerpt,
                  }}
                  itemProp="description"
                />
              </section>
            </article>
          </li>
        )
      })}
    </ol>
  </div>
);


Articles.propTypes = {
  articles: PropTypes.array,
};


export default Articles
